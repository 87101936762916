import React from "react";
import PortableText from "./portableText";

import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";

const maybeImage = (illustration) => {
  let img = null;
  if (
    illustration &&
    illustration.disabled !== true &&
    illustration.image &&
    illustration.image.asset
  ) {
    const fluidProps = getFluidGatsbyImage(
      illustration.image.asset._id,
      { maxWidth: 960 },
      clientConfig.sanity
    );

    img = <img className="w-full" src={fluidProps.src} alt={illustration.image.alt} />;
  }
  return img;
};

const InfoRow = (props) => {
  const img = maybeImage(props.illustration);

  return (
    <div className="flex flex-col w-full sm:w-1/2 md:w-1/3 mb-0 md:mb-10">
      {props.title && <h3 className="text-3xl text-white">{props.title}</h3>}
      {img && (
        <div
          style={{ maxWidth: "84px" }}
          className="flex bg-white bg-opacity-10 rounded-full p-5 mx-auto md:mx-0 mb-4"
        >
          {img}
        </div>
      )}
      <div className="leading-normal text-md md:text-lg font-light text-white mt-0 mb-8 m-auto md:m-0 pr-0 w-2/3 md:w-full md:pr-16 text-center md:text-left">
        <PortableText blocks={props.text} />
      </div>
    </div>
  );
};

const InfoRows = (props) => {
  const contentRows = (props.rows || [])
    .filter((r) => !r.disabled)
    .map((r, i) => {
      return <InfoRow key={r._key} {...r} />;
    });

  return (
    <section className="container mx-auto mt-4 sm:mt-2 md:mt-0 pt-12 pb-4 px-4 sm:px-8 md:px-12 bg-primary">
      <div>
        <h1 className="text-3xl md:text-5xl font-semibold leading-tight text-white mb-2">
          {props.title}
        </h1>
        <h2 className="text-xl md:text-2xl font-normal leading-tight text-white mb-10">
          <PortableText blocks={props.text} />
        </h2>
        <div className="leading-normal text-md md:text-lg font-light text-white text-left md:text-left mx-0 md:mx-0 mt-0 mb-12 md:mb-8">
          <p>{props.listTitle}</p>
        </div>
        <div className="flex flex-wrap">
          {contentRows}
          {props.info && (
            <div className="flex flex-col w-2/3 md:w-3/12 bg-white bg-opacity-10 rounded-xl py-6 md:py-4 px-4 mx-auto md:mx-0 mt-8 md:mt-0 mb-12">
              <p className="leading-normal text-md md:text-lg font-light text-white m-auto md:m-0 pr-0 w-2/3 w-full text-center md:text-left my-0 md:my-0">
                {props.info}
              </p>
              <div className="mt-4 md:mt-8 flex justify-center md:justify-start">
                {props.cta && props.cta.title && (
                  <CTALink
                    {...props.cta}
                    buttonActionClass="mx-auto ml-4 hover:underline bg-white text-gray-800 font-semibold rounded-full my-6 py-4 px-8 shadow-lg"
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {props.highlight && (
          <p className="bg-white text-primary leading-normal text-sm md:text-md font-normal rounded-md p-6 mb-10">
            {props.highlight}
          </p>
        )}
      </div>
    </section>
  );
};

export default InfoRows;
