import React from "react";

const CTAColumn = ({ icon, ctas = [] }) => {
  const actions = ctas
    .filter((c) => c.title)
    .map((c) => {
      return (
        <a
          href={c.landingPageRoute.slug.current}
          className="m-auto text-primary center text-xl md:text-1xl font-semibold leading-tight text-primary mb-10 scale-hover"
        >
          <div className="bg-secondary m-auto mb-4 rounded-full h-256 md:h-40 w-256 md:w-40 flex items-center justify-center">
            <img className="m-auto w-24 md:w-20" src={icon.image.asset.url} alt="Icon" />
          </div>
          {c.title}
        </a>
      );
    });

  return <div className="flex items-center flex flex-col w-full sm:w-1/2 md:w-1/5">{actions}</div>;
};

const CTAColumns = ({ title, columns }) => {
  const cols = columns
    .filter((c) => !c.disabled)
    .map((c, i) => {
      return <CTAColumn icon={c.icon.image.asset.url} width={columns.length} key={c._key} {...c} />;
    });

  return (
    <section className="container mx-auto text-center pt-12 sm:pt-12 md:pt-0 pb-12 md:pb-24 px-4 sm:px-8 md:px-8">
      <h1 className="text-4xl md:text-5xl font-semibold leading-tight text-primary mb-10">
        {title}
      </h1>
      <div className="flex flex-wrap justify-between text-center opacity-hover">{cols}</div>
    </section>
  );
};

export default CTAColumns;
