import React from "react";
import PortableText from "./portableText";

function TextCenter(props) {
  return (
    <div className="container mx-auto py-12 px-4 sm:px-8 md:px-8 w-full max-w-4xl">
      <div className="flex flex-col w-full justify-center items-start">
        {props.heading && (
          <h1 className="w-full mt-0 mb-6 text-4xl md:text-5xl font-semibold leading-tight text-primary text-center">
            {props.heading}
          </h1>
        )}
        {props.textBlock && (
          <div className="w-full leading-normal text-md md:text-lg text-body font-light">
            <PortableText blocks={props.textBlock} />
          </div>
        )}
      </div>
    </div>
  );
}

export default TextCenter;
