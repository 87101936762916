import React from "react";
import PortableText from "./portableText";
import CTALink from "./CTALink";

function HeroBG(props) {
  return (
    <div
      className={`container mx-auto mt-4 py-12 px-4 sm:px-8 md:px-12 flex flex-wrap items-start bg-cover bg-black bg-opacity-50 ${
        props.alignment === "Left to right"
          ? "flex-col-reverse md:flex-row"
          : "flex-col-reverse md:flex-row-reverse"
      }`}
      style={{
        backgroundImage: `url(${props.illustration.image.asset.url})`,
        backgroundBlendMode: "multiply",
      }}
    >
      <div
        className={`flex flex-col w-full md:w-1/2 justify-center items-start ${
          props.alignment === "Left to right" ? "pr-0 md:pr-24" : "pl-0 md:pl-24"
        }`}
      >
        {props.label && (
          <p className="text-white uppercase tracking-loose w-full m-0 font-medium">
            {props.label}
          </p>
        )}
        {props.heading && (
          <h1 className="mt-0 mb-4 text-4xl md:text-5xl font-semibold leading-tight text-white">
            {props.heading}
          </h1>
        )}
        {props.tagline && (
          <div className="leading-normal text-md md:text-lg text-white mb-4 font-light">
            <PortableText blocks={props.tagline} />
          </div>
        )}
        {props.cta && props.cta.title && <CTALink {...props.cta} />}
      </div>
    </div>
  );
}

export default HeroBG;
