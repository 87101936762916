import React from "react";
import { graphql } from "gatsby";

import Hero from "../components/hero";
import HeroBG from "../components/heroBG";
import HeroFull from "../components/heroFull";
import HeroTabbed from "../components/heroTabbed";
import InfoRows from "../components/InfoRows";
import Callout from "../components/callout";
import CTAColumns from "../components/cta-columns";
import CTA from "../components/cta";
import Form from "../components/form";
import Tabs from "../components/tabs";
import TextCenter from "../components/textCenter";
import PageTitle from "../components/pageTitle";
import GalleryBlock from "../components/galleryBlock";
import ExtraCurricular from "../components/extraCurricular";

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      keywords
      openGraph {
        title
        description
      }
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      address
      title
      facebook
      email
      phone
      keywords
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;

  const content = (page._rawContent || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "infoRows":
          el = <InfoRows key={c._key} {...c} />;
          break;
        case "hero":
          el = <Hero key={c._key} {...c} />;
          break;
        case "heroBG":
          el = <HeroBG key={c._key} {...c} />;
          break;
        case "heroFull":
          el = <HeroFull key={c._key} {...c} />;
          break;
        case "heroTabbed":
          el = <HeroTabbed key={c._key} {...c} />;
          break;
        case "callout":
          el = <Callout key={c._key} {...c} />;
          break;
        case "ctaColumns":
          el = <CTAColumns key={c._key} {...c} />;
          break;
        case "ctaPlug":
          el = <CTA key={c._key} {...c} />;
          break;
        case "form":
          el = <Form key={c._key} {...c} />;
          break;
        case "tabs":
          el = <Tabs key={c._key} {...c} />;
          break;
        case "textCenter":
          el = <TextCenter key={c._key} {...c} />;
          break;
        case "pageTitle":
          el = <PageTitle key={c._key} {...c} />;
          break;
        case "galleryBlock":
          el = <GalleryBlock key={c._key} {...c} />;
          break;
        case "extraCurricular":
          el = <ExtraCurricular key={c._key} {...c} />;
          break;
        default:
          el = null;
      }
      return el;
    });

  const menuItems = page.navMenu && (page.navMenu.items || []);
  const pageTitle = data.route && !data.route.useSiteTitle && page.title;
  const keywords = data.route && data.route.keywords || data.site.keywords
  const description = data.route && data.route.openGraph.description || data.site.openGraph.description
  const image = data.route && data.route.openGraph.image || data.site.openGraph.image

  const address = data.site.address;
  const facebook = data.site.facebook;
  const email = data.site.email;
  const phone = data.site.phone;

  return (
    <Layout
      navMenuItems={menuItems}
      textWhite={true}
      address={address}
      facebook={facebook}
      email={email}
      phone={phone}
      pageTitle={pageTitle}
    >
      <SEO
        title={pageTitle}
        description={description}
        keywords={keywords}
        image={image}

        bodyAttr={{
          class: "leading-normal tracking-normal text-white bg-tertiary",
        }}
      />
      <main>{content}</main>
    </Layout>
  );
};

export default Page;
