import React from "react";
import { ArrowRight } from "phosphor-react";

function Callout(props) {
  return (
    <div className="container mx-auto py-12 px-4 sm:px-8 md:px-8 flex flex-wrap items-start">
      <div className="flex flex-wrap w-full justify-between items-center py-6 md:py-2 px-10 bg-secondary rounded-full">
        {props.heading && (
          <h1 className="w-full md:w-1/2 text-base md:text-lg font-medium leading-tight text-primary mt-0 md:mt-4 mb-4 md:mb-4 text-center md:text-left">
            {props.heading}
          </h1>
        )}
        {props.cta.link && (
          <div className="m-auto md:m-0">
            <a
              href={props.cta.link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-base text-white font-semibold no-underline bg-primary pl-5 rounded-full"
            >
              {props.cta.title}
              <div
                className="flex items-center justify-center bg-orange rounded-full ml-4"
                style={{ width: "36px", height: "36px" }}
              >
                <ArrowRight color="white" size="30" />
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default Callout;
