import React from "react";
import { useRef, useCallback } from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";

import { getFluidGatsbyImage } from "gatsby-source-sanity";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ArrowLeft, ArrowRight } from "phosphor-react";

function Hero(props) {
  const slider = useRef();

  const next = useCallback(() => {
    slider.current.slickNext();
  }, [slider]);

  const prev = useCallback(() => {
    slider.current.slickPrev();
  }, [slider]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      className={`container mx-auto py-4 sm:py-8 md:py-12 px-4 sm:px-8 md:px-8 flex flex-wrap items-start ${
        props.alignment === "Left to right"
          ? "flex-col-reverse md:flex-row"
          : "flex-col-reverse md:flex-row-reverse"
      }`}
    >
      {/* Left col */}
      <div
        className={`flex flex-col w-full md:w-1/2 justify-center items-start ${
          props.alignment === "Left to right" ? "pr-0 md:pr-24" : "pl-0 md:pl-24"
        }`}
      >
        {props.label && (
          <p className="text-primary uppercase tracking-loose w-full m-0 font-medium">
            {props.label}
          </p>
        )}
        {props.heading && (
          <h1 className="my-4 text-4xl md:text-5xl font-semibold leading-tight text-primary">
            {props.heading}
          </h1>
        )}
        {props.tagline && (
          <div className="leading-normal text-md md:text-lg text-body font-light">
            <PortableText blocks={props.tagline} />
          </div>
        )}
        {props.cta && props.cta.title && <CTALink {...props.cta} />}
      </div>
      {/* Right col */}
      {props.slideshow && (
        <div className="relative md:sticky top-0 items-start w-full md:w-1/2 mb-4 md:mb-16 pt-0 md:pt-8">
          <div className="text-center relative">
            <Slider
              ref={slider}
              {...settings}
              sx={{
                ".slick-active": {
                  zIndex: 200,
                },
              }}
            >
              {props.slideshow.map((image, index) => {
                let imageProps = getFluidGatsbyImage(
                  image.illustration.image.asset._id,
                  { maxWidth: 960 },
                  clientConfig.sanity
                );
                return (
                  <img
                    key={index}
                    className="w-full z-50"
                    src={imageProps.src}
                    alt={image.illustration.alt}
                  />
                );
              })}
            </Slider>
            {props.slideshow.length > 1 && (
              <div className="flex row absolute right-0 bottom-0 p-6">
                <button
                  className="flex items-center justify-center bg-orange rounded-full"
                  style={{ width: "48px", height: "48px" }}
                  onClick={prev}
                >
                  <ArrowLeft size={36} />
                </button>
                <button
                  className="flex items-center justify-center bg-orange rounded-full ml-2"
                  style={{ width: "48px", height: "48px" }}
                  onClick={next}
                >
                  <ArrowRight size={36} />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Hero;
