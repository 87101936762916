import React from "react";
import PortableText from "../components/portableText";
import CTALink from "./CTALink";

const CTA = ({ title, body, ctas }) => (
  <section className="container mx-auto text-center py-16 px-4 sm:px-8 md:px-8">
    <h1 className="mb-2 text-3xl md:text-4xl font-semibold leading-tight text-center text-primary">
      {title}
    </h1>
    <p className="text-1xl md:text-2xl leading-tight text-body mb-6">
      <PortableText blocks={body} />
    </p>
    <div className="flex">
      {(ctas || []).map((c, i) => (
        <div className="flex-1 text-gray-700 text-center py-2" key={i}>
          <CTALink
            key={`cta_${i}`}
            {...c}
            buttonActionClass="mx-auto ml-4 hover:underline bg-white text-gray-800 font-semibold rounded-full my-6 py-4 px-8 shadow-lg"
          />
        </div>
      ))}
    </div>
  </section>
);

export default CTA;
