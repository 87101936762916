import React from "react";
import { useRef } from "react";
import PortableText from "./portableText";
import CTALink from "./CTALink";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HeroFull(props) {
  const slider = useRef();

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="container mx-auto flex bg-secondary pt-2 pb-4 sm:pb-8 md:pb-8 px-4 sm:px-8 md:px-8">
      <div className="w-full m-auto justify-center items-center">
        <Slider ref={slider} {...settings}>
          {props.slideshow.map((content, index) => {
            return (
              <div className="w-full text-center m-auto h-heroS md:h-heroL">
                <div
                  key={index}
                  className="w-full text-center m-auto absolute inset-0 bg-cover bg-center h-heroS md:h-heroL"
                  style={{
                    backgroundImage: `url(${content.illustration.image.asset.url})`,
                  }}
                >
                  <div className="absolute inset-0 flex justify-center flex-col bg-black bg-opacity-50 z-50">
                    <div>
                      {content.heading && (
                        <h1 className="my-4 text-4xl md:text-5xl font-semibold leading-tight text-white w-4/5 mx-auto">
                          {content.heading}
                        </h1>
                      )}
                      {content.tagline && (
                        <div className="leading-normal text-md md:text-lg mb-4 font-light text-white">
                          <PortableText blocks={content.tagline} />
                        </div>
                      )}
                      {content.cta && content.cta.title && (
                        <div className="hero-button">
                          <CTALink {...content.cta} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
}

export default HeroFull;
