import React from "react";
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Tabs(props) {
  return (
    <div
      className="container mx-auto pt-6 sm:pt-8 md:pt-12 pb-4 sm:pb-8 md:pb-0 px-4 sm:px-8 md:px-8"
      id="tabs"
    >
      <h2 className="text-2xl md:text-3xl font-semibold leading-tight text-primary mb-6 md:mb-12">
        {props.heading}
      </h2>
      <ul className="flex flex-wrap items-start bg-white md:rounded-full md:overflow-hidden w-full flex list-none md:rounded-full m-0">
        {props.tabs.items.map((i, index) => (
          <li
            className="md:flex-grow w-full md:w-auto flex text-body m-0 p-0 flex justify-center items-center font-medium"
            key={index}
          >
            <Link
              to={`/${kebabCase(i.title)}`}
              partiallyActive={true}
              activeClassName="active-tab"
              className="w-full py-2 text-center"
            >
              {i.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Tabs;
