import React, { Fragment } from "react";
import { Link } from "gatsby";

function PageTitle(props) {
  return (
    <div className="container mx-auto pt-8 pb-4 md:pb-0 px-4 sm:px-8 md:px-8 w-full">
      <div className="flex flex-col w-full">
        {props.heading && (
          <Fragment>
            <div className="flex leading-normal text-base text-body font-medium mb-0">
              <Link to="/" className="hover:text-primary">
                Home
              </Link>
              &nbsp;&nbsp;>&nbsp;&nbsp;
              <h1 className="leading-normal text-base text-body font-medium mb-0">
                {props.heading}
              </h1>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default PageTitle;
