import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import clientConfig from "../../client-config";

import { getFluidGatsbyImage } from "gatsby-source-sanity";

function GalleryBlock(props) {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      sanityGallery {
        slideshow {
          illustration {
            image {
              alt
              asset {
                url
                _id
              }
            }
          }
        }
      }
    }
  `);

  const slideshow = data.sanityGallery.slideshow;

  return (
    <div className="container mx-auto pt-4 sm:pt-8 md:pt-12 pb-12 sm:pb-12 md:pb-12 px-4 sm:px-8 md:px-8 grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 gap-4 sm:gap-8 md:gap-6">
      {slideshow &&
        slideshow.map((image, index) => {
          let imageProps = getFluidGatsbyImage(
            image.illustration.image.asset._id,
            { maxWidth: 960 },
            clientConfig.sanity
          );
          return (
            <img key={index} className="w-full" src={imageProps.src} alt={image.illustration.alt} />
          );
        })}
    </div>
  );
}

export default GalleryBlock;
