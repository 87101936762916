import React from "react";
import { Fragment, useState, useRef } from "react";
import PortableText from "./portableText";
import clientConfig from "../../client-config";
import CTALink from "./CTALink";

import { getFluidGatsbyImage } from "gatsby-source-sanity";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { motion, AnimatePresence } from "framer-motion";

function Hero(props) {
  const [expandedIndex, setExpandedIndex] = useState(0);

  return (
    <div className="container mx-auto pt-6 md:pt-0 pb-6 sm:pb-12 px-4 sm:px-8 md:px-8 flex flex-wrap items-start">
      <div className="flex flex-col w-full md:w-1/2 justify-center items-start pr-0 md:pr-24">
        <div className="flex w-full mb-4">
          {props.tabs.map((content, index) => (
            <Tabs
              key={index}
              content={content}
              isOpen={expandedIndex === index}
              onClick={() => setExpandedIndex(index)}
            />
          ))}
        </div>
        {props.tabs.map((content, index) => (
          <Info key={index} content={content} isOpen={expandedIndex === index} />
        ))}
      </div>
      <div
        className="flex flex-col w-full md:w-1/2 justify-center items-start"
        style={{ minHeight: "50vh" }}
      >
        {props.tabs.map((content, index) => (
          <Slideshow key={index} content={content} isOpen={expandedIndex === index} />
        ))}
      </div>
    </div>
  );
}

const Tabs = ({ content, isOpen, onClick }) => {
  return (
    <Fragment>
      <motion.div
        initial={false}
        onClick={onClick}
        className="text-body font-medium w-full border-b-2 pb-2 cursor-pointer"
        style={{ borderColor: isOpen ? "#1A5DC8" : "inherit", opacity: isOpen ? 1 : 0.3 }}
      >
        {content.label}
      </motion.div>
    </Fragment>
  );
};

const Info = ({ content, isOpen }) => {
  return (
    <Fragment>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { display: "block" },
              collapsed: { display: "none" },
            }}
            transition={{ duration: 0.3 }}
            className="text-primary"
          >
            {content.heading && (
              <h1 className="my-4 text-4xl md:text-5xl font-semibold leading-tight text-primary">
                {content.heading}
              </h1>
            )}
            {content.text && (
              <div className="leading-normal text-md md:text-lg text-body mb-8 font-light">
                <PortableText blocks={content.text} />
              </div>
            )}
            {content.cta && content.cta.title && (
              <div className="mb-6 md:mb-10">
                <CTALink
                  {...content.cta}
                  buttonActionClass="mx-auto ml-4 hover:underline bg-white text-gray-800 font-semibold rounded-full my-6 py-4 px-8 shadow-lg"
                />
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  );
};

const Slideshow = ({ content, isOpen }) => {
  const slider = useRef();

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "vertical-dots",
  };

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { display: "block" },
            collapsed: { display: "none" },
          }}
          transition={{ duration: 0.3 }}
          className="w-full pr-12 mt-4 md:mt-0"
        >
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1 },
              collapsed: { opacity: 0 },
            }}
            transition={{ delay: 0.1, duration: 0.4 }}
          >
            <Slider
              ref={slider}
              {...settings}
              sx={{
                ".slick-active": {
                  zIndex: 200,
                },
              }}
            >
              {content.slideshow.map((image, index) => {
                let imageProps = getFluidGatsbyImage(
                  image.illustration.image.asset._id,
                  { maxWidth: 960 },
                  clientConfig.sanity
                );
                return (
                  <img
                    key={index}
                    className="w-full z-50"
                    src={imageProps.src}
                    alt={image.illustration.alt}
                  />
                );
              })}
            </Slider>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Hero;
