import React from "react";
import Logos from "../assets/Curriculum_Logos.png";

function ExtraCurricular(props) {
  return (
    <section className="container mx-auto py-12 px-4 sm:px-8 md:px-8 flex flex-wrap items-start">
      <div className="flex flex-wrap w-full justify-between items-center p-8 bg-white">
        <div className="w-full md:w-1/2 pr-0 md:pr-24">
          {props.title && (
            <h1 className="mt-0 mb-0 text-lg md:text-xl font-semibold leading-tight text-primary text-center md:text-left">
              {props.title}
            </h1>
          )}
          <p className="mt-2 mb-8 md:mb-0 w-full text-base md:text-lg leading-tight text-body text-center md:text-left">
            {props.copy}
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <img src={Logos} alt="Curriculumn Logos" />
        </div>
      </div>
    </section>
  );
}

export default ExtraCurricular;
