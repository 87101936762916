import React from "react";
import { Fragment, useState, useRef, useCallback } from "react";

import NetlifyForm from "react-netlify-form";
import ReactMapGL, { Marker } from "react-map-gl";

import { MapPin } from "phosphor-react";

import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function Form(props) {
  const mapRef = useRef();

  const [viewport, setViewport] = useState({
    latitude: -27.49358,
    longitude: 152.9152,
    zoom: 15
  });

  const handleViewportChange = useCallback(newViewport => setViewport(newViewport), []);

  const mapToken = process.env.MAP_TOKEN;

  return (
    <div className="container mx-auto pt-2 md:pt-12 pb-0 md:pb-2 px-4 sm:px-8 md:px-8 flex flex-wrap items-start">
      <div className="flex flex-col w-full md:w-1/2 justify-center items-start pr-0 md:pr-16">
        <h1 className="text-3xl md:text-5xl font-semibold leading-tight text-primary mb-2">
          {props.heading}
        </h1>
        {props.intro && (
          <div className="leading-normal text-md md:text-lg text-body font-light mb-4">
            <p>{props.intro}</p>
          </div>
        )}
        <NetlifyForm name="contact">
          {({ loading, error, success }) => (
            <Fragment>
              {!loading && !success && (
                <Fragment>
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="w-full mb-2">
                    <label htmlFor="name" id="name" className="w-full text-body font-normal">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      required
                      className="w-full my-2 p-4 text-body"
                      placeholder="Your name"
                    />
                  </div>
                  <div className="w-full mb-2">
                    <label
                      htmlFor="email-address"
                      id="email-address"
                      className="w-full text-body font-normal"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="email-address"
                      required
                      className="w-full my-2 p-4 text-body"
                      placeholder="Your email address"
                    />
                  </div>
                  <div className="w-full mb-4">
                    <label htmlFor="message" id="message" className="w-full text-body font-normal">
                      Message
                    </label>
                    <textarea
                      name="message"
                      required
                      className="w-full my-2 p-4 text-body"
                      rows="8"
                      placeholder="Your message"
                    />
                  </div>
                  <button
                    style={{ height: "36px" }}
                    className="inline-flex items-center text-base text-white font-semibold no-underline bg-primary px-5 rounded-full"
                  >
                    {props.submitText}
                  </button>
                </Fragment>
              )}
              {loading && <p className="text-body text-base my-6 font-medium">Loading...</p>}
              {error && (
                <p className="text-body text-base my-6 font-medium">
                  Your information was not sent. Please try again later.
                </p>
              )}
              {success && (
                <p className="text-body text-base my-6 font-medium">Thank you for contacting us!</p>
              )}
            </Fragment>
          )}
        </NetlifyForm>
      </div>
      <div
        className="flex flex-col w-full md:w-1/2 justify-center items-start text-primary mt-12 md:mt-0"
        style={{ height: "80vh" }}
      >
        <ReactMapGL
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={mapToken}
        >
          <Marker latitude={-27.49358} longitude={152.9152} offsetLeft={-20} offsetTop={-10}>
            <a
              href="https://goo.gl/maps/nhEYfRNL7bHVTEaq5"
              rel="noopener noreferrer"
              aria-label="Map Link"
              target="_blank"
              className="block rounded-full bg-orange p-2"
            >
              <MapPin size={36} color="White" />
            </a>
          </Marker>
        </ReactMapGL>
      </div>
    </div>
  );
}

export default Form;
